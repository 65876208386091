<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('warehouse_config.commodity_market_price_list') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col sm="4">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_config.crop_name')"
                label-for="commodity_group_id"
            >
              <v-select name="commodity_name_id"
                        v-model="search.commodity_name_id"
                        :reduce="text => text.value" label="text"
                        :options= commoditynameList
                        :placeholder="$t('globalTrans.select')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="date"
            >
              <template v-slot:label>
                {{$t('warehouse_config.date')}} <span class="text-danger">*</span>
              </template>
              <flat-pickr class="form-control"
                          v-model="search.price_date"
                          placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_config.commodity_market_price_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="listReload">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(commodity_group_name)="data">
                      {{ data.item.commodity_group_name }}
                    </template>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                      <template v-slot:cell(division_id)="data">
                      {{ getDataById(data.item.division_id, 'division') }}
                    </template>
                     <template v-slot:cell(district_id)="data">
                      {{ getDataById(data.item.district_id, 'district') }}
                    </template>
                     <template v-slot:cell(upazilla_id)="data">
                      {{ getDataById(data.item.upazilla_id, 'upazilla') }}
                    </template>
                    <template v-slot:cell(warehouse_id)="data">
                      {{ getDataById(data.item.warehouse_id, 'warehouse') }}
                    </template>
                    <template v-slot:cell(commodity_group_id)="data">
                      {{ getDataById(data.item.commodity_group_id, 'cropType') }}
                    </template>
                    <template v-slot:cell(commodity_name_id)="data">
                      {{ getDataById(data.item.commodity_name_id, 'cropName') }}
                    </template>
                    <template v-slot:cell(market_info_id)="data">
                      {{ getDataById(data.item.market_info_id, 'marketInfo') }}
                    </template>
                     <template v-slot:cell(price)="data">
                     {{ $n(data.item.price) }}
                    </template>
                    <template v-slot:cell(price_date)="data">
                      {{ data.item.price_date|dateFormat }}
                    </template>
                    <template v-slot:cell(status)="data">
                       {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="pagination.totalRows"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" ok-title="Close" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>

  </b-container>
</template>
<script>

import Form from './Form'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { warehouseMarketPriceList, warehouseMarketPriceToggleStatus, userStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        division_id: 0,
        district_id: 0,
        region_id: 0,
        upazilla_id: 0,
        union_id: 0,
        commodity_name_id: null,
        warehouse_id: 0
      },
      districtList: [],
      unionList: [],
      upazilaList: [],
      regionDistrictLists: [],
      warehouseList: []
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('warehouse_config.commodity_market_price_entry') : this.$t('warehouse_config.commodity_market_price_update')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_information.division'), class: 'text-left' },
          { label: this.$t('warehouse_information.district'), class: 'text-left' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_config.crop_type'), class: 'text-left' },
          { label: this.$t('warehouse_config.crop_name'), class: 'text-left' },
          { label: this.$t('warehouse_config.market'), class: 'text-left' },
          { label: this.$t('warehouse_config.commodity_current_price'), class: 'text-left' },
          { label: this.$t('warehouse_config.date'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_id' },
          { key: 'district_id' },
          { key: 'upazilla_id' },
          { key: 'warehouse_id' },
          { key: 'commodity_group_id' },
          { key: 'commodity_name_id' },
          { key: 'market_info_id' },
          { key: 'price' },
          { key: 'price_date' },
          { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_id' },
          { key: 'district_id' },
          { key: 'upazilla_id' },
          { key: 'warehouse_id' },
          { key: 'commodity_group_id' },
          { key: 'commodity_name_id' },
          { key: 'market_info_id' },
          { key: 'price' },
          { key: 'price_date' },
          { key: 'status' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    regionList: function () {
      return this.$store.state.ExternalUserIrrigation.warehouse.regionList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
    },
    commoditynameList: function () {
      return this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.filter(item => item.status === 0)
    }
  },
  async created () {
      this.checkApprovedUser()
  },
  methods: {
    async searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(warehouseServiceBaseUrl, warehouseMarketPriceToggleStatus, item)
    },
    async loadData () {
    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const userId = this.$store.state.Auth.authUser.username
      const res = await RestApi.getData(authServiceBaseUrl, '/user/farmer-profile/' + userId)
      if (res.data.warehouse_id) {
        const params = Object.assign({}, this.search, {
        warehouse_id: res.data.warehouse_id,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
        })

        await RestApi.getData(warehouseServiceBaseUrl, warehouseMarketPriceList, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      } else {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    async checkApprovedUser () {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        const status = result.status
        if (status === 1) {
          this.loadData()
        } else {
          this.$router.push({ name: 'external_user.warehouse_farmer' + '.dashboard' })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    },
    getDataById (Id, master) {
         if (master === 'division') {
            if (!Id) {
                return null
            } else {
                const targetId = parseInt(Id)
                 const tergetName = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(document => document.value === targetId && document.status === 0)
                 if (tergetName) {
                     return this.$i18n.locale === 'en' ? tergetName.text_en : tergetName.text_bn
                 } else {
                     return null
                 }
            }
        }
        if (master === 'district') {
            if (!Id) {
                return null
            } else {
                const targetId = parseInt(Id)
                 const tergetName = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(document => document.value === targetId && document.status === 0)
                 if (tergetName) {
                     return this.$i18n.locale === 'en' ? tergetName.text_en : tergetName.text_bn
                 } else {
                     return null
                 }
            }
        }
        if (master === 'warehouse') {
            if (!Id) {
                return null
            } else {
                const targetId = parseInt(Id)
                 const tergetName = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.find(document => document.value === targetId && document.status === 0)
                 if (tergetName) {
                     return this.$i18n.locale === 'en' ? tergetName.text_en : tergetName.text_bn
                 } else {
                     return null
                 }
            }
        }
        if (master === 'cropType') {
            if (!Id) {
                return null
            } else {
                const targetId = parseInt(Id)
                 const tergetName = this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList.find(document => document.value === targetId && document.status === 0)
                 if (tergetName) {
                     return this.$i18n.locale === 'en' ? tergetName.text_en : tergetName.text_bn
                 } else {
                     return null
                 }
            }
        }
        if (master === 'cropName') {
            if (!Id) {
                return null
            } else {
                const targetId = parseInt(Id)
                 const tergetName = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.find(document => document.value === targetId && document.status === 0)
                 if (tergetName) {
                     return this.$i18n.locale === 'en' ? tergetName.text_en : tergetName.text_bn
                 } else {
                     return null
                 }
            }
        }
        if (master === 'upazilla') {
            if (!Id) {
                return null
            } else {
                const targetId = parseInt(Id)
                 const tergetName = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(document => document.value === targetId && document.status === 0)
                 if (tergetName) {
                     return this.$i18n.locale === 'en' ? tergetName.text_en : tergetName.text_bn
                 } else {
                     return null
                 }
            }
        }
        if (master === 'marketInfo') {
          if (!Id) {
            return null
          } else {
            const targetId = parseInt(Id)
            const tergetName = this.$store.state.ExternalUserIrrigation.warehouse.marketInfoList.find(document => document.value === targetId && document.status === 0)
            if (tergetName) {
              return this.$i18n.locale === 'en' ? tergetName.text_en : tergetName.text_bn
            } else {
              return null
            }
          }
        }
    }
  }
}
</script>
